import ApiService from "@/core/services/ApiService";
import { FormData } from "@/core/types/gws-medical/operational-hours/FormData";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";
import { defineStore } from "pinia";

const authStore = useAuthenticationStore();
export const useOperationalQuotaFormStore = defineStore({
  id: "operationalQuotaFormStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: {} as FormData,
      stateError: false,
      stateErrors: [] as string[],
      stateErrorMsg: "",
      stateUpdateId: "",
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    formData(state) {
      return state.stateFormData;
    },
    error(state) {
      return state.stateError;
    },
    errors(state) {
      return state.stateErrors;
    },
    errorMessage(state) {
      return state.stateErrorMsg;
    },
  },
  actions: {
    async insertData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          facility_id: authStore.user.org_id || "",
          days: this.stateFormData.days || "",
          start_hour: this.stateFormData.start_hour || "",
          end_hour: this.stateFormData.end_hour || "",
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/medical/facilities/operational`,
          payload as AxiosRequestConfig
        );
        if (response.data) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async updateData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          facility_id: authStore.user.org_id || "",
          days: this.stateFormData.days || "",
          start_hour: this.stateFormData.start_hour || "",
          end_hour: this.stateFormData.end_hour || "",
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/medical/facilities/operational/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        if (metaResp) {
          metaResp.errors[0].forEach((err) => {
            this.stateErrors.push(err.message);
          });
        } else {
          this.stateErrors.push(error);
        }
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async deleteData(id: string) {
      this.stateIsLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.delete(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/medical/facilities/operational/${id}`
          );
        if (response.status === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
    },
    setFormData(data: FormData) {
      this.stateFormData = {
        facility_id: data.facility_id,
        days: data.days,
        start_hour: data.start_hour,
        end_hour: data.end_hour,
      };
    },
  },
});
